import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import RevealSettingsModal from './revealSettings.modal';

import TemplatePreview from '../../templateEditor/templatePreview.component';

import { navigate } from '../../../root.navigation';

import { SignagePlayerStatus, DeviceStatus } from '../kiosk.component';

class KioskSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Kiosk Settings");
		this.state = {
			...this.state,
			revealSettingsOpen: false
		}
	}
	renderModalLeft() {
		const dataGroupID = this.getModelValue('dataGroupID', 'Kiosk', this.props.id);
		const playSignageLoop = this.getModelValue('playSignageLoop', 'Kiosk', this.props.id);
		const revealSettingsID = this.getModelValue('revealSettingsID', 'Kiosk', this.props.id);
		const getRevealSetting = this.modelPropValueGetter('RevealSettings', revealSettingsID);
		const revealSolution = getRevealSetting('revealSolution');
		const revealDevice = getRevealSetting('revealDevice');
		const deviceID = getRevealSetting('deviceID');
		const acbDeviceName = this.getModelValue('deviceName', 'Device', deviceID);

		//console.log(revealSolution, revealSettingsID, this.props);

		return (<>
			<RevealSettingsModal
				id={revealSettingsID}
				kioskID={this.props.id}
				open={this.state.revealSettingsOpen}
				closeModal={() => this.setState({ revealSettingsOpen: false })}
			/>
			{this.renderModelTextInput({ name: 'name', model: 'Kiosk', id: this.props.id })}

			{this.renderModelTextInput({ name: 'description', model: 'Kiosk', id: this.props.id }, true)}

			{this.renderModelCheckBox({
				label: 'Play a Playlist as a Screen Saver',
				name: 'playSignageLoop', 
				model: 'Kiosk', 
				id: this.props.id,
			})}

			{playSignageLoop && this.renderModelDropdown({
				label: 'Screen Saver Playlist',
				name: 'signagePlaylistID', 
				model: 'Kiosk', 
				id: this.props.id,
				options: this.makeDropdownOptions('SignagePlaylist'),
			})}

			{this.renderModelTextInput({
				label: 'Access Pin Code',
				name: 'pinCode',
				model: 'Kiosk',
				numeric: true,
				numberString: true,
				maxLength: 4,
				id: this.props.id,
				onChange: (val) => { console.log(val) }
			})}

			{this.renderModelDropdown({
				label: 'Customer Group',
				name: 'dataGroupID', 
				model: 'Kiosk', 
				id: this.props.id,
				options: this.makeDropdownOptions('DataGroup'),
			})}
			{dataGroupID && <LinkButton title="Edit Customer Group" onPress={() => { 
				navigate('Customers', {id: dataGroupID});
				this.props.closeModal();
			}} />}

			<Text style={[styles.label, { marginTop: 20 }]}>Reveal Settings:</Text>
			<View style={{flexDirection: 'row', gap: 5}}>
				<Text style={styles.heavy}>{revealSolution === 'signage'? 'Signage' : ((revealDevice == 'AS1') ? 'Airshroud' : 'ACB')}:</Text>
				{revealSolution === 'signage'
					? <SignagePlayerStatus id={revealSettingsID} />
					: <DeviceStatus id={deviceID} />
				}
			</View>
			<LinkButton title="Change settings" onPress={() => this.setState({ revealSettingsOpen: true })} />
		</>);
	}
	renderModalRight() {
		const templateID = this.getModelValue('templateID', 'Kiosk', this.props.id);
		return (
			<View>
				<Text style={styles.label}>Preview:</Text>
				<View style={{ flex: 1, borderWidth: 1, borderColor: '#AAA', borderRadius: 5, minHeight: 200, justifyContent: "center", alignItems: "center" }}>
					<TemplatePreview id={templateID} />
				</View>

				{this.renderModelDropdown({
					label: 'Template',
					name: 'templateID',
					model: 'Kiosk',
					id: this.props.id,
					options: this.makeDropdownOptions('KioskTemplate'),
				})}
				{templateID && <LinkButton title="Edit template" onPress={() => { 
					navigate('TemplateEditor', {id: templateID, kioskID: this.props.id});
					this.props.closeModal();
				}} />}

				<View style={{ marginTop: 20 }}>
					{this.renderSelectableTextField('Publish URL', 'https://cxreveal.com/?apiKey=' + this.getModelValue('apiKey', 'Kiosk', this.props.id), true)}
					<Text>Version: {this.getModelValue('publishedVersion', 'Kiosk', this.props.id) || 'Not Published'}</Text>
				</View>
			</View>
		);
	}
	renderModalBottom() {
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Save & Publish" style={{ width: 200 }} onPress={() => {
						this.saveModel(false, null, 'Kiosk', this.props.id)
						.then(() => {
							return this.apiCall('Kiosk', 'publishKiosk', {kioskID: this.props.id})
						})
						.then((response) => {
							console.log(response);
							//refreshRevealScreen
							//return this.apiCall('Kiosk', 'publishKiosk', {kioskID: this.props.id})
						})
						.then(() => {
							this.props.closeModal()
						})
						.catch(console.error);
					}} />
					<OutlineButton title="Save" style={{ width: 100, marginRight: 20 }} onPress={() => this.saveModel(false, null, 'Kiosk', this.props.id).then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default KioskSettingsModal = connect({
	models: [{ model: 'Kiosk' }, { model: 'KioskTemplate', list: true }, { model: 'Device', list: true }, { model: 'DataGroup', list: true }, { model: 'RevealSettings', keyField: 'kioskID', useKey: true, key: (root, props) => props.id }, { model: 'SignagePlaylist', list: true }],
	component: KioskSettingsModal
});

