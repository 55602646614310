import React from 'react';
import { View, Text, StyleSheet, Image, Platform, Pressable } from 'react-native';
import { styles } from '../../main.styles';

import { Helmet } from "react-helmet";
import { SERVER_DOMAIN } from '../../constants';

import * as Font from 'expo-font';
import { availableFonts } from '../../components/fontPicker.component';

import Loader from '../../components/loader.component';
import Button from '../../components/button.component';
import IconButton from '../../components/iconButton.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';
import ButtonPreview from '../../components/buttonPreview.component';
import StyledText from '../../components/styledText.component';
import WebView from '../../components/webView.component';
import TextInput from '../../components/textInput.component';

import { faBackspace, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { Video } from 'expo-av'
import VideoPlayer from 'expo-video-player'

import { APIComponent } from '../ModelComponent';
import { TouchableOpacity } from 'react-native-gesture-handler';
import PlaylistPlayer from '../signage/playlistPlayer.component';

function getFileUri(fileName) {
	if (!fileName) return null;
	return SERVER_DOMAIN + '/customerWebPages/' + fileName;
}

const syncIDOverride = (() => {
	if (!Platform.OS === "web") return null;
  
	const requestParams = new URLSearchParams(window.location.search);
	return requestParams.get('syncID');
})();

const isPreview = (() => {
	if (!Platform.OS === "web") return null;
  
	const requestParams = new URLSearchParams(window.location.search);
	return requestParams.get('preview') === 'true';
})();

function appIsConnected() { 
	return (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOSAppHandler) 
}
                            
function sendAppMessage(message) { 
	if (appIsConnected) window.webkit.messageHandlers.iOSAppHandler.postMessage(message); 
}

let appCheckTimeoutID = 0;

export default class ClientReveal extends APIComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			rawData: null,
			data: {},
			dataReady: false,
			currentPageID: null,
			revealing: false,
			bleAppConnected: false,
			revealStarted: 0,
			playingSignageLoop: null,
			signagePlaylistID: 'default',
			dataOverride: null,
			openModals: [],
			openWebpage: null,
			showCustomerSettings: false,
			customerSettingsPinCode: false,
			userEnteredCustomerSettingsPinCode: false,
		}
		if (Platform.OS === "web") {
			document.documentElement.style.touchAction = 'none';
			document.body.style.touchAction = 'none';
		}
	}
	setError(errorMessage, err) {
		console.error(err || errorMessage);
		this.updateState({
			dataReady: false,
			error: (typeof errorMessage.toString === 'function' ? errorMessage.toString() : errorMessage)
		});
		return false;
	}
	componentDidMount() {
		super.componentDidMount();
		this.apiCall('connectLiveKiosk', { apiKey: this.props.apiKey }).then(response => {
			if (!response.success) return this.setError(response.reason, response.errors);
			return this.apiCall('getPublishedData', { apiKey: this.props.apiKey }).then(response => {
				try {
					console.log(JSON.parse(response.data.publishedJSON));
					this.updateState({
						rawData: JSON.parse(response.data.publishedJSON),
						device: response.data.device
					});
				} catch (err) {
					this.setError('Could not parse JSON data.', err);
				}
			});
		}).catch(err => {
			this.setError(err);
		});

		this.apiListener('refreshRevealScreen', () => {
			console.log("refresh signal received!");
			if (location) location.reload();
		});

		this.apiListener('updateCustomerData', ({ customerName, customerMessage }) => {
			console.log("new customer data received!");
			this.updateState({ dataOverride: { customerName, customerMessage } })
		});

		window.onAppInfo = (appData) => {
			const data = this.state.data;
			const newState = { 
				bleAppConnected: appData.bleConnected, 
				revealing: appData.revealing 
			}

			if (this.state.revealing && !appData.revealing) {
				if (data.kiosk.showPostRevealWebpage && data.kiosk.postRevealWebpage) {
					newState.openWebpage = data.kiosk.postRevealWebpage;
				}
			}

			this.updateState(newState);
		}

		window.onAppShowKeypad = () => {
			const pinCode = this.state.data.kiosk.pinCode;
			this.setState({ showCustomerSettings: true, customerSettingsPinCode: pinCode, userEnteredCustomerSettingsPinCode: '', tempDataOverride: {customerName: this.state.dataOverride.customerName, customerMessage: this.state.dataOverride.customerMessage} });
		}
		window.onAppCloseKeypad = () => {
			this.setState({ showCustomerSettings: false, userEnteredCustomerSettingsPinCode: false});
		}

		if (appIsConnected()) {
			clearInterval(appCheckTimeoutID);
			appCheckTimeoutID = setInterval(() => {
				sendAppMessage("getAppInfo");
			}, 1000);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (!this.checkData()) return null;
		const data = this.state.rawData;

		const currentPageID = this.state.currentPageID || data.KioskTemplate[0].children.TemplatePages[0].data.id;
		const pageChanged = prevState.currentPageID != currentPageID;

		const kiosk = data.Kiosk;
		const revealSettings = data.RevealSettings[0].data;

		const dataOverrideState = this.state.dataOverride;
		const dataOverride = dataOverrideState || { customerName: kiosk.customerNameOverride, customerMessage: kiosk.customerMessageOverride };
		const dataOverrideChanged = (JSON.stringify(prevState.dataOverride) != JSON.stringify(dataOverride));

		//console.log(dataOverride, overrideDataChanged);

		if (dataOverrideState && dataOverrideChanged) {
			const messageString = `<p style='text-align:center;'><span style='font-size:64px; font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";'>` + dataOverride.customerName + `<br/>` + dataOverride.customerMessage + `</span></p>`;
			const { signageSyncID, signageCommand } = revealSettings;
			//console.log(revealSettings);

			this.apiCall('sendSignageMessage', {
				apiKey: this.props.apiKey,
				syncID: syncIDOverride || signageSyncID,
				command: signageCommand,
				messageString
			}).then(response => {
				console.log(response);
			});
		}

		if (!this.state.currentPageID) {
			// preload all component and background images
			data.KioskTemplate[0].children.TemplatePages.forEach(templatePage => {
				if (templatePage.data.backgroundImage) Image.prefetch(getFileUri(templatePage.data.backgroundImage));
				function preloadComponentImages(item) {
					switch (item.data.type) {
						case 'image':
							const component = item.children?.TemplateComponentImage?.[0]?.data || {};
							if (component.imagePath) Image.prefetch(getFileUri(component.imagePath));
							break;
						case 'modal':
							console.log(item)
							if (item.data.backgroundImage) Image.prefetch(getFileUri(item.data.backgroundImage));
							item.children?.TemplateComponents?.forEach(item => preloadComponentImages(item));
							item.children?.TemplateComponentModals?.forEach(item => preloadComponentImages(item));
							break;
					}
				}
				templatePage.children?.TemplateComponentModals.forEach(item => preloadComponentImages(item));
				templatePage.children?.TemplateComponents.forEach(item => preloadComponentImages(item));
			});
		}

		if (!this.state.currentPageID || pageChanged || dataOverrideChanged) {
			const template = data.KioskTemplate[0].data;
			const templatePages = Object.fromEntries(data.KioskTemplate[0].children.TemplatePages.map(item => [item.data.id, item]));

			const pageComponents = { button: {}, text: {}, image: {}, playlist: {}, countdown: {}, modal: {} };

			const addComponent = (list, item) => {
				const itemData = item.data;
				let component = null;
				const positionData = item.children?.TemplatePositionData?.[0]?.data;
				if (positionData) {
					switch (itemData.type) {
						case 'button':
							component = item.children?.TemplateComponentButton?.[0]?.data || {};
							component.buttonStyle = item.children?.TemplateComponentButton?.[0]?.children?.ButtonStyle?.[0]?.data || {};
							if (positionData && !component.buttonStyle.resizable) {
								positionData.width = component.buttonStyle.width;
								positionData.height = component.buttonStyle.height;
							}
							component.buttonStyle.textStyle = item.children?.TemplateComponentButton?.[0]?.children?.ButtonStyle?.[0]?.children?.TextStyle?.[0]?.data || {};
							break;
						case 'text':
							component = item.children?.TemplateComponentText?.[0]?.data || {};
							component.textStyle = item.children?.TemplateComponentText?.[0]?.children?.TextStyle?.[0]?.data || {};
							component.overrideData = dataOverride;
							break;
						case 'image':
							component = item.children?.TemplateComponentImage?.[0]?.data || {};
							break;
						case 'playlist':
							component = item.children?.TemplateComponentPlaylist?.[0]?.data || {};
							const signagePlaylist = item.children?.TemplateComponentPlaylist?.[0]?.children?.SignagePlaylist?.[0];
							component.playlist = {...signagePlaylist?.data, items: []};
							component.playlist.items = signagePlaylist?.children?.SignagePlaylistItem?.map(item => item?.data);
							component.playlist.items.sort((a, b) => a.playlistIndex - b.playlistIndex);
							break;
						case 'countdown':
							component = item.children?.TemplateComponentCountdown?.[0]?.data || {};
							break;
						case 'modal':
							component = { button: {}, text: {}, image: {}, playlist: {}, countdown: {}, modal: {} };
							item.children?.TemplateComponents?.forEach(item => {
								addComponent(component, item);
							});
							item.children?.TemplateComponentModals?.forEach(item => {
								addComponent(component, item);
							});
							break;
					}
					if (!(itemData.type in list)) list[itemData.type] = {};
					list[itemData.type][itemData.id] = {
						...itemData,
						component: component,
						positionData,
					};
				}
			}

			templatePages[currentPageID].children?.TemplateComponentModals.forEach(item => {
				addComponent(pageComponents, item);
			});

			templatePages[currentPageID].children?.TemplateComponents.forEach(item => {
				addComponent(pageComponents, item);
			});

			const currentPage = templatePages[currentPageID].data;

			const signagePlaylist = data.SignagePlaylist;
			const signagePlaylistItems = data.SignagePlaylistItems;

			const playingSignageLoop = (() => {
				if (this.state.playingSignageLoop === null) { //if is initial loading
					return (kiosk.playSignageLoop && signagePlaylist && signagePlaylistItems && signagePlaylistItems.length > 0)
				}
				// otherwise don't change it
				return this.state.playingSignageLoop;
			})();

			this.updateState({
				currentPageID,
				dataOverride,
				data: { kiosk, revealSettings, template, templatePages, currentPage, pageComponents, signagePlaylist, signagePlaylistItems },
				dataReady: true,
				playingSignageLoop,
				openModals: pageChanged ? [] : [...this.state.openModals]
			});
		}
	}
	checkData() {
		const data = this.state.rawData;
		if (!data || this.state.error) return false;
		if (!data.RevealSettings?.[0]?.data) return this.setError("Reveal Settings not found in Published Kiosk");
		if (!data.KioskTemplate?.[0]?.data) return this.setError("Template not set in Published Kiosk");
		if (data.KioskTemplate[0].children?.TemplatePages?.length === 0) return this.setError("No Template Pages not found in Published Kiosk");
		return this.state.data;
	}
	onButtonPress(button, action, actionData) {
		const data = this.state.data;

		console.log(button, action, actionData);

		if (!isPreview) this.apiCall('addKioskLog', {
			data: {
				organisationID: data.kiosk.organisationID,
				siteID: data.kiosk.siteID,
				kioskID: data.kiosk.id,
				logEvent: action,
				logText: JSON.stringify({buttonID: button.id, buttonName: button.name, actionData})
			},
			children: null,
			parent: null,
			parents: [],
		}).then((response) => {
			if (response.success) console.log("Event logged");
			else console.log(response);
		}).catch(err => console.log(err));

		if (action === 'reveal') {
			if (data.revealSettings.sendWebsocketOnRevealButtonPress) {
				this.apiCall('triggerSignageCommand', {
					apiKey: this.props.apiKey,
					syncID: syncIDOverride || data.revealSettings.signageSyncID,
					command: data.revealSettings.signageCommand
				}).then(response => {
					console.log(response);
				});
			}
			const hasCountdown = Object.values(data.pageComponents.countdown)?.filter(countdown => countdown.component.duration > 0).length;
			if (hasCountdown) this.updateState({ countdownRunning: true });
			else this.triggerReveal();
		} else if (action === 'gotoPage' && actionData in data.templatePages) {
			this.updateState({
				currentPageID: actionData
			});
		} else if (action === 'signageCommand' && actionData) {
			const { signageSyncID } = data.revealSettings;
			this.apiCall('triggerSignageCommand', {
				apiKey: this.props.apiKey,
				syncID: syncIDOverride || signageSyncID,
				command: actionData
			}).then(response => {
				console.log(response);
				if (response.data.result && response.data.result.toLowerCase() == 'ok') console.log("COMMAND SENT SUCCESSFULLY");
			});
		} else if (action === 'playDefaultSignagePlaylist') {
			this.setState({playingSignageLoop: true})
		} else if (action === 'playSignagePlaylist' && actionData) {
			this.setState({playingSignageLoop: true, signagePlaylistID: parseInt(actionData)})
		} else if (action === 'openModal' && actionData) {
			const modalID = parseInt(actionData);
			if (!this.state.openModals.includes(modalID)) this.setState({ openModals: [...this.state.openModals, modalID] });
		} else if (action === 'closeModal') {
			const openModals = [...this.state.openModals];
			openModals.pop();
			this.setState({ openModals });
		} else if (action === 'openWebpage' && actionData) {
			this.setState({ openWebpage: actionData });
		} else if (action === 'showCustomerSettings') {	
			const pinCode = data.kiosk.pinCode ? data.kiosk.pinCode : actionData;
			this.setState({ showCustomerSettings: true, customerSettingsPinCode: pinCode, userEnteredCustomerSettingsPinCode: '', tempDataOverride: {customerName: this.state.dataOverride.customerName, customerMessage: this.state.dataOverride.customerMessage} });
		}
	}
	triggerReveal() {
		const data = this.state.data;
		const { revealDevice, connectionMethod, commandDuration, deviceSlot, revealSpeed, deviceID, signageSyncID, signageCommand, signageCanvasID, signageZoneID } = data.revealSettings;

		const revealSolution = this.state.bleAppConnected ? "ble" : data.revealSettings.revealSolution;

		this.updateState({ countdownRunning: false });

		const revealComplete = () => {
			const completeState = {
				revealing: false
			};
			if (data.kiosk.showPostRevealWebpage && data.kiosk.postRevealWebpage) {
				completeState.openWebpage = data.kiosk.postRevealWebpage;
			}
			this.updateState(completeState);
		}
		const revealStarted = (useTimeout = true) => {
			console.log("TRIGGERING REVEAL", { revealSolution, revealDevice, connectionMethod, commandDuration, deviceSlot, revealSpeed, deviceID, signageSyncID, syncIDOverride, signageCommand, signageCanvasID, signageZoneID });
			this.updateState({ revealStarted: Date.now() });
			if (useTimeout) setTimeout(() => revealComplete(), (commandDuration || 21) * 1000);
		}

		if (revealSolution == 'ble') {
			sendAppMessage("reveal");
			revealStarted(false);
		} else if (revealSolution == 'acb') {
			const device = this.state.device || {};
			if (!device) return;
			const deviceShortID = device.shortID;
			const deviceKey = device.apiKey;
			this.updateState({ revealing: true, revealStarted: Date.now() });
			fetch(SERVER_DOMAIN + '/api/reveal?duration=' + commandDuration + '&slot=' + deviceSlot + '&did=' + revealDevice + '&speed=' + revealSpeed + '&device=' + deviceShortID + '&key=' + deviceKey)
				.then((response) => response.text())
				.then((responseText) => {
					responseText = 'ok';
					if (responseText == 'ok') {
						revealStarted();
					} else {
						this.updateState({ revealing: false });
					}
				})
				.catch((error) => {
					console.error(error);
					this.updateState({ revealing: false });
				});
		} else if (revealSolution == 'signage') {
			this.apiCall('triggerSignageCommand', {
				apiKey: this.props.apiKey,
				syncID: syncIDOverride || signageSyncID,
				command: signageCommand
			}).then(response => {
				console.log(response);
				if (response.data.result && response.data.result.toLowerCase() == 'ok') {
					revealStarted();
				} else {
					this.updateState({ revealing: false });
				}
			});
		}
	}
	renderContent() {
		if (!this.state.dataReady) return (
			<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
				{this.state.error
					? this.renderError()
					: <Loader size="large" text="Loading Kiosk..." />
				}
			</View>
		);

		const data = this.checkData();
		if (!data) return this.renderError();

		console.log(data);

		const { kiosk, revealSettings, template, templatePages, currentPage, pageComponents, signagePlaylist, signagePlaylistItems } = data;
		const hasSignageLoop = (kiosk.playSignageLoop && signagePlaylist && signagePlaylistItems && signagePlaylistItems.length > 0);

		console.log(hasSignageLoop, signagePlaylistItems);

		const playlistForPlayer = (() => {
			if (!hasSignageLoop) return null;
			return signagePlaylistItems.map(item => ({
				name: item.name,
				index: item.playlistIndex,
				itemType: item.itemType,
				duration: item.duration,
				url: getFileUri(item.filePath),
			}));
		})();

		console.log(playlistForPlayer);

		window.document.title = currentPage.name + ' - ' + kiosk.name + ' - ' + kiosk.publishedVersion;

		//console.log(pageComponents);

		return (<View style={{ flex: 1, backgroundColor: currentPage.backgroundColor || '#FFFFFF' }}>
			<Helmet>
				<meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=no; shrink-to-fit=no" />
			</Helmet>
			<View style={{ width: template.screenResolutionX, height: template.screenResolutionY, overflow: 'hidden', position: 'relative' }}>
				{this.state.openWebpage && <>
					<View style={{ flex: 1, flexDirection: 'column', position: 'absolute', height: '100%', width: '100%', zIndex: 1001 }}>
						<View style={{ alignItems: 'center', padding: 10, backgroundColor: '#FFF', flexDirection: 'row' }}>
							<Button title="Close" onPress={() => this.setState({ openWebpage: null })} />
							{/*<Text style={[{flex:1, textAlign: 'center'}, styles.subTitle]}>{this.state.openWebpage}</Text>*/}
						</View>
						<View style={{ flex: 1 }}>
							<WebView url={this.state.openWebpage} style={{ height: '100%', width: '100%', background: '#EEE' }} />
						</View>
					</View>
				</>}
				{(hasSignageLoop && playlistForPlayer && this.state.playingSignageLoop) ? 
					<PlaylistPlayer 
						playlist={playlistForPlayer}
						height={'100%'} 
						width={'100%'} 
						backgroundColor={signagePlaylist.backgroundColor}
						muted={signagePlaylist.mutePlaylist}
						onPress={() => this.setState({playingSignageLoop: false})} 
					/>
				: <ClientRevealPage
					revealing={this.state.revealing}
					countdownRunning={this.state.countdownRunning}
					page={currentPage}
					components={pageComponents}
					openModals={this.state.openModals}
					clientReveal={this}
					showCustomerSettings={this.state.showCustomerSettings}
					hideCustomerSettings={(updatedCustomerSettings) => {
						const stateUpdate = {showCustomerSettings: false};
						if (updatedCustomerSettings) {
							stateUpdate.dataOverride = { 
								customerName: updatedCustomerSettings.customerName, 
								customerMessage: updatedCustomerSettings.customerMessage 
							}
						}
						this.updateState(stateUpdate);
					}}
					onButtonPress={(button, action, actionData) => this.onButtonPress(button, action, actionData)}
					onCountdownComplete={(countdown) => this.triggerReveal()}
				/>}
			</View>
		</View>)
	}
}


class ClientRevealPage extends APIComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
		}
	}
	renderContent() {
		const page = this.props.page;
		const components = this.props.components;

		const revealing = this.props.revealing;

		const padButtonStyle = {width: 64, height: 64, borderRadius: 32, justifyContent: "center", alignContent: "center"};
		const padStyle = {borderRadius: 32, width: 64, height: 64};
		const padTextStyle = {fontWeight: 200, fontSize: '200%', height: 64, paddingTop: 13};

		return (<View style={{ width: '100%', height: '100%' }}>
			{page.backgroundImage && <Image source={{ uri: this.getImageUri(page.backgroundImage) }} style={{
				width: '100%',
				height: '100%',
				position: 'absolute',
				left: 0,
				top: 0,
			}} />}
			<View style={{ flex: 1, zIndex: 1 }}>
				{Object.values(components.image).map(component => (
					<DisplayImage key={component.id} dataField={component} source={{ uri: this.getImageUri(component.component.imagePath) }} onPress={(action, actionData) => {
						return this.props.onButtonPress && this.props.onButtonPress(component, action, actionData)
					}} />
				))}

				{Object.values(components.playlist).map(component => (
					<DisplayPlaylist key={component.id} dataField={component} onPress={(action, actionData) => {
						return this.props.onButtonPress && this.props.onButtonPress(component, action, actionData)
					}} />
				))}

				{Object.values(components.button).map(component => {
					if (component.component.action === 'reveal' && (revealing || this.props.countdownRunning)) return null;
					return (
						<DisplayButton key={component.id} dataField={component} onPress={(action, actionData) => {
							return this.props.onButtonPress && this.props.onButtonPress(component, action, actionData)
						}} />
					)
				})}

				{Object.values(components.text).map(component => (
					<DisplayText key={component.id} dataField={component} />
				))}

				{Object.values(components.countdown).map(component => {
					if (this.props.countdownRunning && component.component.duration > 0) {
						return <DisplayCountdown key={component.id} dataField={component} onComplete={() => this.props.onCountdownComplete(component)} />
					}
				})}

				{Object.values(components.modal).map(component => {
					if (this.props.openModals.includes(component.id)) return (
						<View key={component.id} style={{ flex: 1, zIndex: 100, backgroundColor: '#000000A0' }}>
							<View style={{ position: 'absolute', backgroundColor: (component.backgroundColor || '#FFFFFF'), opacity: component.positionData.opacity / 100, left: component.positionData.x, top: component.positionData.y, width: component.positionData.width, height: component.positionData.height, borderRadius: component.borderRadius, overflow: 'hidden' }}>
								<ClientRevealPage
									revealing={revealing}
									countdownRunning={this.props.countdownRunning}
									page={component}
									components={component.component}
									openModals={this.props.openModals}
									onButtonPress={(button, action, actionData) => {
										this.props.onButtonPress(button, action, actionData)
									}}
									onCountdownComplete={(countdown) => this.props.onCountdownComplete(countdown)}
								/>
							</View>
						</View>
					);
				})}

				{this.props.showCustomerSettings && <View style={{ flex: 1, zIndex: 1000, backgroundColor: '#000000A0' }}>
					<View style={{ position: 'relative', backgroundColor: '#FFFFFF', left: '50%', top: '50%', width: 400, marginLeft: -200, height: 600, marginTop: -300, borderRadius: 5, overflow: 'hidden' }}>
						{(this.props.clientReveal.state.customerSettingsPinCode && this.props.clientReveal.state.customerSettingsPinCode != this.props.clientReveal.state.userEnteredCustomerSettingsPinCode) ? <View style={{padding: 20, width: '100%', height: '100%'}}>
							<Text style={{fontWeight: 200, fontSize: '200%', margin: 20, textAlign: 'center'}}>Enter Access Code</Text>
							<Text style={{fontWeight: 400, fontSize: '300%', textAlign: 'center'}}>{(()=>{ 
								let code = "- - - -";
								const userCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode+"";
								if (userCode) {
									if (userCode.length > 0) {
										code = userCode[0] + " - - -";
									}
									if (userCode.length > 1) {
										code = userCode[0] + " "+ userCode[1] +" - -";
									}
									if (userCode.length > 2) {
										code = userCode[0] + " "+ userCode[1] +" "+ userCode[2] + " -";
									}
									if (userCode.length > 3) {
										code = userCode[0] + " "+ userCode[1] +" "+ userCode[2] + " " +  userCode[3];
									}
								}
								return code;
							})()} {(this.props.clientReveal.state.userEnteredCustomerSettingsPinCode.length == 4 && this.props.clientReveal.state.customerSettingsPinCode != this.props.clientReveal.state.userEnteredCustomerSettingsPinCode) && <IconButton icon={faTimesCircle} color={'#AA0000'} size={32} onPress={() => {
								this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: ""})
							}} />}</Text>
							<View style={{flexDirection: 'column', marginTop: 20, gap: 30, height: '100%'}}>
								<View style={{flexDirection: 'row',  justifyContent: "space-around", alignContent: "space-around"}}>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="7" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "7"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="8" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "8"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="9" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "9"})
									}}/>
								</View>

								<View style={{flexDirection: 'row', justifyContent: "space-around", alignContent: "space-around"}}>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="4" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "4"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="5" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "5"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="6" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "6"})
									}}/>
								</View>

								<View style={{flexDirection: 'row', justifyContent: "space-around", alignContent: "space-around"}}>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="1" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "1"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="2" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "2"})
									}}/>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="3" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "3"})
									}}/>
								</View>
								<View style={{flexDirection: 'row', justifyContent: "space-around", alignContent: "space-around"}}>
									<View style={{ width: 60, height: 60, alignItems: 'center', justifyContent: "space-around"}}>
										<LinkButton title='Cancel' style={{}} onPress={() => {this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: ""}); this.props.hideCustomerSettings();}} />
									</View>
									<Button titleStyle={padTextStyle} style={padStyle} buttonStyle={padButtonStyle} title="0" onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length < 4) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode + "0"})
									}}/>
									<IconButton style={{alignItems: 'center', justifyContent: "space-around"}} buttonStyle={{fontWeight: 200, fontSize: '200%', width: 64, height: 64, borderRadius: 32}} size={50} icon={faBackspace} onPress={() => {
										const currentCode = this.props.clientReveal.state.userEnteredCustomerSettingsPinCode;
										if (currentCode.length > 0) this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: currentCode.substring(0, currentCode.length-1)})
									}}/>
								</View>
							</View>
						</View>
						: <View style={{padding: 20, height: '100%', width: '100%'}}>
							<Text style={{fontWeight: 200, fontSize: '200%'}}>Customer Settings</Text>

							<TextInput label='Customer Name' style={{marginTop: 20}} initialFocus={true} value={this.props.clientReveal.state.tempDataOverride.customerName} onChangeText={(customerName) => {this.props.clientReveal.updateState({tempDataOverride: {customerName, customerMessage: this.props.clientReveal.state.tempDataOverride.customerMessage}})}}/>
							<TextInput label='Customer Message' style={{marginTop: 20}} inputStyle={{height: 100}} multiline={true} value={this.props.clientReveal.state.tempDataOverride.customerMessage} onChangeText={(customerMessage) => {this.props.clientReveal.updateState({tempDataOverride: {customerMessage, customerName: this.props.clientReveal.state.tempDataOverride.customerName}})}} />
						
							<View style={{ position: 'absolute', left: 0, right: 0, bottom: 0}}>
								<View style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginVertical: 20}}>
									<Button title='Save Settings' style={{width: 200}} onPress={() => {
										const data = {
											customerName: this.props.clientReveal.state.tempDataOverride.customerName,
											customerMessage: this.props.clientReveal.state.tempDataOverride.customerMessage
										}
										this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: ""});
										this.props.hideCustomerSettings(data);

										this.apiCall('saveCustomerSettings', {
											apiKey: this.props.clientReveal.props.apiKey,
											customerNameOverride: data.customerName,
											customerMessageOverride: data.customerMessage,
										}).then(response => {
											console.log(response);
										});
									}} />
								</View>
							</View>
												
							{appIsConnected() && <View style={{position: 'absolute', left: 20, bottom: 20}}><LinkButton title='Close Kiosk' style={{}} onPress={() => { sendAppMessage("closeKiosk"); }} /></View>}
							<View style={{position: 'absolute', right: 20, bottom: 20}}><LinkButton title='Cancel' style={{}} onPress={() => {this.props.clientReveal.updateState({userEnteredCustomerSettingsPinCode: ""}); this.props.hideCustomerSettings();}} /></View>
						</View>
						}
					</View>
				</View>}
			</View>
		</View>)
	}
}




function Bold(props) {
	return (<Text style={{ fontWeight: '500' }}>{props.children}</Text>);
}
function Center(props) {
	return (<Text style={{ textAlign: 'center' }}>{props.children}</Text>);
}
function Red(props) {
	return (<Text style={{ color: '#AA0000' }}>{props.children}</Text>);
}

class PreviewElement extends React.Component {
	extraState = {}
	constructor(props) {
		super(props);
		this.state = {
			...this.extraState
		}
	}
	zIndex = 10;
	createPositionDataStyle = () => {
		const dataField = this.props.dataField;
		return {
			width: dataField.positionData.width,
			height: dataField.positionData.height,
			position: 'absolute',
			left: dataField.positionData.x,
			top: dataField.positionData.y,
			zIndex: this.zIndex + dataField.positionData.z,
			opacity: dataField.positionData.opacity / 100,
		};
	}
	parentRender = (content) => {
		return (
			<View style={this.createPositionDataStyle()}>
				{content}
			</View>
		);
	}
}

class DisplayImage extends PreviewElement {
	render() {
		const dataField = this.props.dataField;
		const component = dataField.component;
		const action = component.action;
		const actionData = component.actionData;
		const source = this.props.source;

		function renderContent() {
			const uri = source.uri;
			const isVideo = uri && (uri.endsWith('.mp4') || uri.endsWith('.webp') || uri.endsWith('.avi'));
			
			return (isVideo 
				? <VideoPlayer videoProps={{
					shouldPlay: true,
					resizeMode: "contain",
					source: { uri },
					isLooping: true,
					//isMuted: true,
				  }}
				  defaultControlsVisible={false}
				  timeVisible={false}
				  slider={{visible: false}}
				  /*
					playbackCallback={(info) => {
						console.log(info);
					}
				  */
				  errorCallback={(err) => console.error(err)} 
				  style={{width: dataField.positionData.width, height: dataField.positionData.height}} />
				: <Image source={source} style={{ width: '100%', height: '100%' }} />
			);
		}

		if (action && action != "None") return this.parentRender(
			<TouchableOpacity style={{ width: dataField.positionData.width, height: dataField.positionData.height }} onPress={() => this.props.onPress && this.props.onPress(action, actionData)}>
				{renderContent()}
			</TouchableOpacity>
		);
		else return this.parentRender(renderContent());
	}
}

class DisplayButton extends PreviewElement {
	constructor(props) {
		super(props);
		this.zIndex = props.zIndex || 100;
	}
	render() {
		const component = this.props.dataField.component;
		const action = component.action;
		const actionData = component.actionData;
		if (action == 'reveal') return this.parentRender(
			<ButtonPreview onPressIn={() => this.props.onPress && this.props.onPress(action, actionData)} button={this.props.dataField} />
		);
		else return this.parentRender(
			<ButtonPreview onPress={() => {
				if (this.props.onPress) this.props.onPress(action, actionData);
			}} button={this.props.dataField} />
		);
	}
}

class DisplayText extends PreviewElement {
	extraState = {
		fontLoaded: false,
		fontFamily: null
	}
	componentDidUpdate(prevProps, prevState) {
		const fontStyle = this.props.dataField.component.textStyle;
		if (prevState.fontFamily !== fontStyle.fontFamily) {
			//console.log(availableFonts[fontStyle.fontFamily])
			const needsLoad = !!availableFonts[fontStyle.fontFamily];
			if (needsLoad) {
				const font = { [fontStyle.fontFamily]: require('../../assets/fonts/' + availableFonts[fontStyle.fontFamily]) }

				Font.loadAsync(font).then((result) => {
					this.setState({ fontLoaded: true });
				})
			}
			this.setState({ fontFamily: fontStyle.fontFamily, fontLoaded: !needsLoad })
		}
	}
	render() {
		const fontStyle = this.props.dataField.component.textStyle;
/*
		if ((!this.state.fontLoaded) && fontStyle.fontFamily in availableFonts && availableFonts[fontStyle.fontFamily]) {
			const font = { [fontStyle.fontFamily]: require('../../assets/fonts/' + availableFonts[fontStyle.fontFamily]) }

			Font.loadAsync(font).then((result) => {
				this.setState({ fontLoaded: true });
			})
		}
		*/

		let text = "";
		if (this.props.dataField.component.isLinked) {
			const fieldName = this.props.dataField.component.linkedDataSetFieldName;
			if (fieldName in this.props.dataField.component.overrideData) text = this.props.dataField.component.overrideData[fieldName];
		} else {
			text = this.props.dataField.component.text;
		}

		return this.parentRender(
			<View style={{ width: '100%', height: '100%' }}>
				{(text) && /* && (this.state.fontLoaded || (!fontStyle.fontFamily) || availableFonts[fontStyle.fontFamily] === false) */
					<StyledText style={{ textAlign: 'center' }}
						text={text}
						fontFamily={fontStyle.fontFamily}
						fontSize={fontStyle.fontSize}
						fontColor={fontStyle.fontColor}
						centerText={fontStyle.centerText}
					/>
				}
			</View>
		);
	}
}

class DisplayPlaylist extends PreviewElement {
	extraState = {
		playlistLoading: true,
	}
	/*
	componentDidMount() {
		this._mounted = true;
		this.player = null;
	}
	componentWillUnmount() {
		this._mounted = false;
	}
	*/
	render() {
		const playlist = this.props.dataField.component;
		const positionData = this.createPositionDataStyle();

		const playlistForPlayer = playlist.playlist.items.map(item => {
			return {
				name: item.name,
				index: item.playlistIndex,
				itemType: item.itemType,
				duration: item.duration,
				url: getFileUri(item.filePath),
			};
		});

		const action = playlist.action;

		return this.parentRender(this.state.playlistLoading
			? <Loader />
			: <PlaylistPlayer 
				playlist={playlistForPlayer} 
				muted={playlist.mutePlaylist} 
				width={positionData.width} 
				height={positionData.height} 
				onPress={(paused, setPaused) => {
					if (action && action != "None") {
						console.log({action})
						if (action == "playToggle") setPaused(paused == 1 ? 0 : 1)
						else if (this.props.onPress) this.props.onPress(action, playlist.actionData);
					}
				}}
				onReady={() => {
					if (this._mounted && this.state.playlistLoading) this.setState({ playlistLoading: false });
				}}
			/>);
	}
}

class DisplayCountdown extends PreviewElement {
	constructor(props) {
		super(props);

		this.counterTID = 0;
	}
	componentDidMount() {
		this.counterTID = setTimeout(() => {
			this.props.onComplete();
		}, 1000 * this.props.dataField.component.duration);
	}
	componentWillUnmount() {
		clearTimeout(this.counterTID);
	}
	render() {
		if (this.props.dataField.component.hidden) return ([]);
		const width = this.props.dataField.positionData.width
		const height = this.props.dataField.positionData.height
		const countdownValue = this.props.dataField.component.duration;

		return this.parentRender(renderCountdown({ width, height, countdownValue }));
	}
}


function renderCountdown({ width, height, countdownValue, run = true }) {
	const circumference = 2 * Math.PI * ((width / 2) - 4);

	const countdownHTML = `<style>
		body {
			padding: 0;
			margin: 0;
			overflow: hidden;
		}

		.item {
			position: relative;
			float: left;
		}
  
		.item h2 {
			text-align:center;
			position: absolute;
			font-family: Arial, sans-serif;
			font-size: `+ width * 0.7 + `%;
			top: 50%;
			left: 50%;
			margin: 0;
			transform-origin: center center; 
			transform: translate(-50%, -50%);
			color: #FFF;
		}
  
		svg {
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
  
		#circle_animation {
			stroke-dasharray: `+ circumference + `; /* this value is the pixel circumference of the circle */
			stroke-dashoffset: 0;
			transition: all 1s linear;
		}
	</style>
  
	<div class="item html">
		<svg width="`+ width + `" height="` + height + `" xmlns="http://www.w3.org/2000/svg">
		<g>
		  <title>Layer 1</title>
		  <circle id="circle_animation" r="`+ Math.max(0, (width / 2) - 4) + `" cy="` + height / 2 + `" cx="` + width / 2 + `" stroke-width="8" stroke="#EEE" stroke-linecap="round" fill="rgba(0,0,0,0.5)"/>
		</g>
		</svg>
		<h2 id="text">`+ countdownValue + `<br/>Seconds</h2>
	</div>
  
	<script>
	  setTimeout(() => {
		var time = `+ countdownValue + `;
		var maxOffset = `+ circumference + `;
  
		/* Need initial run as interval hasn't yet occured... */
		`+ (run ? '' : '//') + ` document.getElementById('circle_animation').style.strokeDashoffset = -(maxOffset/time);
  
		function run() {
			var i = time - 1;
			var interval = setInterval(function() {
				document.getElementById('text').innerHTML = i+'<br/>Seconds';
				if (i <= 0) {  	
					clearInterval(interval);
					return;
				}
				i--;
				const newOffset = -((time-i)*(maxOffset/time));
				document.getElementById('circle_animation').style.strokeDashoffset = newOffset;
			}, 1000);
		}
		`+ (run ? 'run();' : '') + `
	  }, 0);
	</script>`;
	return (
		<View style={{ width: '100%', height: '100%' }}>
			<WebView disableTouch={true} style={{ width: '100%', height: '100%' }} html={countdownHTML} />
		</View>
	);
}